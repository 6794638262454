import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import constants from "../../Constants";
import "./ViewItem.css";
import ImageCarousel from "./ImageCarousel";
import CountdownTimer from "../Auction/CountdownTimer"; // Import the CountdownTimer component

const ViewItem = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const auctionId = location.state?.auctionId || "None";
  const fromPage = location.state?.fromPage || "None";
  const [itemDetails, setItemDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSeller, setIsSeller] = useState(false);
  const [isBuyer, setIsBuyer] = useState(false);
  const [images, setImages] = useState([]);
  const [showBidModal, setShowBidModal] = useState(false);
  const [bidPrice, setBidPrice] = useState("");
  const [bidError, setBidError] = useState("");
  const [bidLoading, setBidLoading] = useState(false);
  const [shake, setShake] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [username, setUsername] = useState(null);
  const [isValidBid, setIsValidBid] = useState(false);
  const [minimumBid, setMinimumBid] = useState(0);
  const [maximumBid, setMaximumBid] = useState(0);
  const [effectiveBasePrice, setEffectiveBasePrice] = useState(0);
  const [userHighestBidder, setUserHighestBidder] = useState(false);
  const [auctionDetails, setAuctionDetails] = useState(null);
  const [bidStatus, setBidStatus] = useState(null); // State to store bid status winning or losing

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchItemDetails = async () => {
    try {
      setUpdating(true);
      const response = await axiosInstance.get(
        `/${constants.SELLER}/item/${itemId}/`
      );
      setItemDetails(response.data);
      setLoading(false);
      setUpdating(false);
    } catch (error) {
      console.error("Error fetching item details:", error);
      setUpdating(false);
    }
  };

  const fetchItemImages = async () => {
    try {
      const response = await axiosInstance.get(
        `/${constants.SELLER}/item/${itemId}/item-images/`
      );
      setImages(response.data);
    } catch (error) {
      console.error("Error fetching item images:", error);
    }
  };

  const fetchAuctionDetails = async () => {
    try {
      const response = await axiosInstance.get(
        `${constants.SELLER}/auction-details/${itemDetails.auctionId}/`
      );
      setAuctionDetails(response.data);
    } catch (error) {
      console.error("Error fetching auction details:", error);
    }
  };

  const checkBidStatus = async () => {
    const userType = localStorage.getItem("userType");
    if (userType !== "Buyer") return;
    if (username && itemDetails && itemDetails.highestBidder === username) {
      if (itemDetails.isSold) {
        setBidStatus("won");
      } else {
        setBidStatus("winning");
      }
    } else if (itemId) {
      try {
        const response = await axiosInstance.get(
          `${constants.SELLER}/is-losing-item/${itemId}/`
        );
        if (response.data.message === "Losing") {
          setBidStatus("losing");
        }
      } catch (error) {
        console.error("Error checking bid status:", error);
      }
    }
  };

  useEffect(() => {
    // if (loading) return;

    const retrievedUsername = localStorage.getItem("username");
    setUsername(retrievedUsername);
    const userType = localStorage.getItem("userType");
    if (userType === "Seller" && retrievedUsername === constants.SELLER) {
      setIsSeller(true);
    } else if (userType === "Buyer") {
      setIsBuyer(true);
    }

    fetchItemDetails();
    fetchItemImages();

    const interval = setInterval(fetchItemDetails, 3000);

    return () => clearInterval(interval);
  }, [itemId]);

  useEffect(() => {
    if (itemDetails && itemDetails.auctionId) {
      fetchAuctionDetails();
    }
  }, [itemDetails]); // Run the effect when itemDetails is updated

  useEffect(() => {
    setEffectiveBasePrice(
      itemDetails && Number(itemDetails.highestBid || itemDetails.startPrice)
    );
    setMinimumBid(
      effectiveBasePrice +
      Math.min(
        5,
        Math.max(1, Math.round(Math.min(effectiveBasePrice / 10), 5))
      )
    );
    setMaximumBid(Math.max(effectiveBasePrice * 2, effectiveBasePrice + 20));
    setUserHighestBidder(
      itemDetails &&
      itemDetails.highestBidder &&
      itemDetails.highestBidder === username
    );

    setIsValidBid(bidPrice >= minimumBid && bidPrice < maximumBid);
  }, [bidPrice, itemDetails, username]);

  useEffect(() => {
    checkBidStatus();
  }, [itemDetails, username]);

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you'd like to delete this item?"
    );
    if (confirmDelete) {
      try {
        await axiosInstance.delete(`auctioneer/delete-item/${itemId}/`);
        navigate("/inventory");
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  const handleCompleteSale = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you'd like to complete this sale? Have you confirmed the transaction code?"
    );
    if (confirmDelete) {
      try {
        await axiosInstance.post(`auctioneer/complete-sale/${itemId}/`);
        navigate("/sales");
      } catch (error) {
        console.error("Error completing sale of item:", error);
      }
    }
  };

  const handleBid = async () => {
    await fetchItemDetails();
    setShowBidModal(true);
  };

  const handleBidSubmit = async () => {
    if (bidLoading || !isValidBid) return;
    setBidLoading(true);
    setShake(false);

    const sellerName = constants.SELLER;
    const confirmBid = window.confirm(
      `Are you sure you'd like to place a bid of $${bidPrice} for ${itemDetails.name}?`
    );
    if (!confirmBid) {
      setBidLoading(false);
      return;
    }

    try {
      const bidResponse = await axiosInstance.post(
        `${sellerName}/item/${itemId}/bid/`,
        { bid: bidPrice }
      );
      if (bidResponse.status === 200) {
        setShowBidModal(false);
        alert(
          "Congrats! Your bid was successful. Watch out for emails to see if your bid stays on top!"
        );
        fetchItemDetails();
      }
    } catch (error) {
      console.error("Error placing bid:", error);
      setBidError(error.response.data.error);
      setShake(true);
    } finally {
      setBidLoading(false);
    }
  };

  const handleCloseModal = () => {
    setBidError(null);
    setBidPrice("");
    setShowBidModal(false);
    fetchItemDetails();
  };

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  const handleBackClick = () => {
    if (auctionId !== "None") {
      navigate(`/auction-contents/${auctionId}`);
    } else if (fromPage !== "None") {
      navigate(fromPage);
    } else {
      navigate("/");
    }
  };

  const handleAuctionRedirect = async () => {
    navigate(`/auction-contents/${itemDetails.auctionId}`);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const calculateTotal = (bid) => {
    const bidAmount = parseFloat(bid);
    const tax = bidAmount * 0.0825;
    return bidAmount + tax;
  };

  return (
    <div className="view-item-page">
      <div className="back-button-container">
        <span className="back-button" onClick={handleBackClick}>
          ← Back
        </span>
        <span className="to-auction-button" onClick={handleAuctionRedirect}>
          To Auction →
        </span>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : itemDetails ? (
        <div className="item-details">
          {images.length > 0 && (
            <ImageCarousel images={images} onImageClick={handleImageClick} />
          )}
          <h2>{itemDetails.name}</h2>
          {!isSeller && (
            <div
              className={`view-item-price-container ${updating ? "updating" : ""
                }`}
            >
              {itemDetails.highestBid && (
                <div>
                  <strong className="item-price-line">Highest Bid: </strong>{" "}
                  <span className="view-item-price">
                    ${itemDetails.highestBid}
                  </span>
                </div>
              )}
              {!itemDetails.highestBid && (
                <div>
                  <strong className="item-price-line">Start Price: </strong>{" "}
                  <span className="view-item-price">
                    ${itemDetails.startPrice}
                  </span>
                </div>
              )}
            </div>
          )}
          {!isSeller && bidStatus && (
            <p className={`winning-losing-highlight ${bidStatus}`}>
              {bidStatus === "winning"
                ? "   Winning"
                : bidStatus === "losing"
                  ? "   Losing"
                  : bidStatus === "won"
                    ? "   Won. Pick this item up when you're available."
                    : ""}
            </p>
          )}
          <div className="details-section">
            {isSeller && (
              <div>
                <strong>Start Price:</strong>{" "}
                <span>${itemDetails.startPrice}</span>
              </div>
            )}
            {isSeller && (
              <div>
                <strong>Highest Bid:</strong>{" "}
                <span>{itemDetails.highestBid || "NONE"}</span>
              </div>
            )}
            {isSeller && (
              <div>
                <strong>Highest Bidder:</strong>{" "}
                <span>{itemDetails.highestBidder || "NONE"}</span>
              </div>
            )}
            <div>
              <strong>Condition:</strong> <span>{itemDetails.condition}</span>
            </div>
            <div>
              <strong>Description:</strong>{" "}
              <span>{itemDetails.description}</span>
            </div>
            {isSeller && (
              <div>
                <strong>Auction:</strong>{" "}
                <span>{itemDetails.auctionName || "NONE"}</span>
              </div>
            )}
            {isSeller && (
              <div>
                <strong>Status:</strong>{" "}
                <span>{itemDetails.isSold ? "Sold" : "Not Sold"}</span>
                {itemDetails.isSold && (
                  <div>
                    <strong>Transaction Code:</strong>{" "}
                    <span>{itemDetails.transactionCode}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          {isSeller && (
            <div className="view-item-buttons-outer-container">
              <div className="view-item-buttons">
                {!itemDetails.highestBid && (
                  <Link to={"/edit-item/" + itemId} className="action-button">
                    Edit Item
                  </Link>
                )}
                {itemDetails.isSold && (
                  <button
                    onClick={handleCompleteSale}
                    className="action-button"
                  >
                    Complete Sale
                  </button>
                )}
                <button onClick={handleDelete} className="delete-button">
                  Delete
                </button>
              </div>
            </div>
          )}
          {isBuyer && !itemDetails.isSold && (
            <button
              onClick={handleBid}
              className="action-button"
              disabled={bidLoading}
            >
              Place Bid
            </button>
          )}
          {!(isBuyer || isSeller) && (
            <button onClick={handleLoginRedirect} className="action-button">
              Login Now To Bid
            </button>
          )}
          {auctionDetails && (
            <div className="view-item-countdown-timer-container">
              <CountdownTimer
                startTime={auctionDetails.startTime}
                endTime={auctionDetails.endTime}
                onEnd={() => alert("Auction has ended!")} // Optional callback on auction end
              />
            </div>
          )}
        </div>
      ) : (
        <p>No item found.</p>
      )}

      {showBidModal && (
        <div className="bid-modal">
          <div className="bid-modal-content">
            {bidLoading && <div className="loading-bar"></div>}
            <h2>Place Your Bid</h2>
            <p1> Minimum bid: {minimumBid}</p1>
            <p> Maximum bid: {maximumBid}</p>
            <input
              type="number"
              value={bidPrice}
              min={minimumBid}
              max={maximumBid}
              onChange={(e) => setBidPrice(e.target.value)}
              placeholder="Enter your bid"
              className="bid-input"
              disabled={bidLoading}
            />
            {isValidBid && (
              <div className="bid-summary">
                <div className="price-summary-line">
                  <strong> Bid Amount: </strong>{" "}
                  <strong>${parseFloat(bidPrice).toFixed(2)}</strong>
                </div>
                <div className="price-summary-line">
                  <strong> Taxes (8.25%): </strong>{" "}
                  <strong>${(parseFloat(bidPrice) * 0.0825).toFixed(2)}</strong>
                </div>
                <div className="price-summary-line">
                  <strong> Total: </strong>{" "}
                  <strong>${calculateTotal(bidPrice).toFixed(2)}</strong>
                </div>
                <p className="warning-message-highlighted">
                  By placing this bid, you are willing to pay the amount listed
                  in the total section above.
                </p>
              </div>
            )}
            {!isValidBid && (
              <p className="warning-message-highlighted">
                Enter a valid bid to see your total.
              </p>
            )}
            {bidError && (
              <p className={`error-message ${shake ? "shake" : ""}`}>
                {bidError}
              </p>
            )}
            <button
              onClick={handleBidSubmit}
              className="bid-button"
              disabled={bidLoading || !isValidBid}
            >
              Bid
            </button>
            <button
              onClick={handleCloseModal}
              className="close-modal-button"
              disabled={bidLoading}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="image-modal" onClick={handleCloseImageModal}>
          <div className="image-modal-content">
            <img
              src={`${constants.BACKEND_ADDRESS}${selectedImage.image}`}
              alt="Enlarged Item"
              className="enlarged-image"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewItem;
