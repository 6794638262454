import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import constants from '../../Constants';
import '../Front Page/Carousel.css'; // Import CSS for the carousel styling

const ImageCarousel = ({ images, onImageClick }) => {
  if (images.length <= 1) {
    return (
      <div className="single-image-container">
        {images.length === 1 && (
          <img
            src={`${constants.BACKEND_ADDRESS}${images[0].image.substring(1, images[0].image.length)}`}
            alt="Item Image"
            className="d-block w-100"
            onClick={() => onImageClick(images[0])} // Handle image click
          />
        )}
      </div>
    );
  }

  return (
    <Carousel>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <div className='carousel-item-image-container'>
            <img
              src={`${constants.BACKEND_ADDRESS}${image.image}`}
              alt={`Item Image ${index}`}
              onClick={() => onImageClick(image)} // Handle image click
            />
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
