const IP_ADDRESS = process.env.REACT_APP_IP_ADDRESS;

// PROD
const constants = {
  SELLER: "JMJLiquidation",
  BACKEND_ADDRESS: `https://${IP_ADDRESS}/api/`,
  SQUARE_APPLICATION_ID: "sq0idp-QJyjhF0eBbWPa08RhTn9GA",
  SQUARE_LOCATION_ID: "LHFEWDAARJTDN",
  MEDIA_URL: "/media/",
};

// non-prod TODO: add the square app id and location id
// const constants = {
//   SELLER: "sellerTest1",
//   BACKEND_ADDRESS: `https://${IP_ADDRESS}/api/`,
//   SQUARE_APPLICATION_ID: "sq0idp-QJyjhF0eBbWPa08RhTn9GA",
//   SQUARE_LOCATION_ID: "LHFEWDAARJTDN",
//   MEDIA_URL: "/media/"
// };

// local halit
// const constants = {
//   SELLER: "JMJLiquidation",
//   BACKEND_ADDRESS: `http://192.168.1.2:8000/`,
//   SQUARE_APPLICATION_ID: "sq0idp-QJyjhF0eBbWPa08RhTn9GA",
//   SQUARE_LOCATION_ID: "LHFEWDAARJTDN",
//   MEDIA_URL: "/media/",
// };

export default constants;
