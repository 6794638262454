import React, { useState } from "react";
import axiosInstance from "../../axiosInstance";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import "./Auth.css";
import "./Register.css";
import SquareJewelBlack from "../../Assets/Square_Jewel_Black.png";
import constants from "../../Constants";
import TermsPage from "../Common/Terms";

const Register = () => {
  const [step, setStep] = useState(1); // Step 1: User info, Step 2: Card info
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return password.length >= minLength && hasUpperCase && hasSymbol;
  };

  const handleContinue = async (e) => {
    e.preventDefault();
    if (loading) return; // Prevent new requests if already loading
    setLoading(true);

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setErrorMessage(
        "Password must be at least 8 characters long, contain at least one uppercase letter, and contain at least one symbol."
      );
      setLoading(false);
      return;
    }

    if (!termsAccepted) {
      setErrorMessage("You must accept the terms and conditions.");
      setLoading(false);
      return;
    }

    let userCheck = false;
    let emailCheck = true;

    try {
      const response = await axiosInstance.get(
        `register/check-username/${username}/`
      );
      if (response.status === 200) {
        userCheck = true;
        setErrorMessage(""); // Clear any previous error messages
      } else {
        setErrorMessage("Username is already taken.");
      }
    } catch (error) {
      userCheck = false;
      setErrorMessage("Username is already taken.");
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }

    try {
      const response = await axiosInstance.get(
        `register/check-email/${email}/`
      );
      if (response.status === 200) {
        emailCheck = true;
        setErrorMessage(""); // Clear any previous error messages
      } else {
        setErrorMessage("Email is already taken.");
      }
    } catch (error) {
      setErrorMessage("Email is already taken.");
      setLoading(false);
      return;
    } finally {
      setLoading(false);
    }

    if (emailCheck && userCheck) {
      setStep(2);
    }
  };

  const handleRegister = async (token) => {
    if (!token || loading) return; // Prevent new requests if already loading or no token
    setLoading(true);
    const buyer = {
      username,
      email,
      password,
    };

    const data = {
      buyer,
      cardToken: token, // Pass the card token to the backend
    };

    const sellerName = constants.SELLER;

    try {
      const response = await axiosInstance.post(
        `/register-buyer/${sellerName}/`,
        data
      );

      if (response.status === 201) {
        setSuccessMessage("Verify Your Email to Login");
        setUsername("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setFullName("");
        setBillingAddress("");
        setErrorMessage("");
      } else {
        setErrorMessage("Registration failed. Please check your inputs.");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        JSON.stringify(error.response.data) ||
        "Error registering. Please check your inputs."
      );
      setSuccessMessage("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        {loading && <div className="loading-bar"></div>}
        {successMessage ? (
          <h2>{successMessage}</h2>
        ) : (
          <>
            <h2>Register</h2>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {step === 1 && (
              <form onSubmit={handleContinue}>
                <div className="auth-form-group">
                  <label>Username</label>
                  <input
                    type="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    disabled={loading}
                  />
                </div>
                <div className="auth-form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    disabled={loading}
                  />
                </div>
                <div className="auth-form-group">
                  <label>Password</label>
                  <div className="password-container">
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className="auth-form-group">
                  <label>Confirm Password</label>
                  <div className="password-container">
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className="form-group terms">
                  <input
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                    disabled={loading}
                  />
                  <span>
                    I accept the{" "}
                    <span
                      className="terms-link"
                      onClick={() => setShowTermsModal(true)}
                    >
                      terms and conditions
                    </span>
                  </span>
                </div>
                <button
                  type="submit"
                  className="auth-button"
                  disabled={loading}
                >
                  Continue
                </button>
              </form>
            )}

            {step === 2 && (
              <>
                <PaymentForm
                  applicationId={constants.SQUARE_APPLICATION_ID}
                  locationId={constants.SQUARE_LOCATION_ID}
                  cardTokenizeResponseReceived={async (token, buyer) => {
                    handleRegister(token);
                  }}
                >
                  <p>
                    {" "}
                    Finish up registration by providing your credit card
                    information.{" "}
                  </p>
                  <div className="tooltip-container">
                    Why do we need this?
                    <span className="tooltip-text">
                      We need your credit card info to securely process your
                      winning bids' payments. This allows you to place bids
                      without giving payment info every time.
                    </span>
                  </div>
                  <CreditCard
                    buttonProps={{
                      css: {
                        backgroundColor: "var(--primary-background-color)",
                        fontSize: "16px",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "var(--button-hover-color)",
                        },
                      },
                    }}
                  >
                    Complete Registration
                  </CreditCard>
                </PaymentForm>
                <div className="square-badge-container">
                  <img
                    src={SquareJewelBlack}
                    alt="Square Badge"
                    className="square-badge"
                  />
                  <p>Payments processed securely via Square</p>
                </div>
              </>
            )}
          </>
        )}
      </div>

      {showTermsModal && (
        <div className="terms-modal">
          <div className="terms-modal-content">
            <TermsPage />
            <div className="terms-modal-close-container">
              <span className="close" onClick={() => setShowTermsModal(false)}>
                &times;
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
