import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import constants from "../../Constants";
import "./InventoryItemContainer.css";
import "bootstrap/dist/css/bootstrap.min.css";

const InventoryItemContainer = ({
  item,
  isSelecting,
  isSelected,
  onItemClick,
  auctionId = "None",
  fromPage = "None",
  loading = false,
}) => {
  const [mainImage, setMainImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(true);
  const [isSeller, setIsSeller] = useState(false);
  const [bidStatus, setBidStatus] = useState(null); // State to store bid status

  useEffect(() => {
    if (loading) return;

    const fetchMainImage = async () => {
      try {
        const response = await axiosInstance.get(
          `${constants.SELLER}/item/${item.id}/item-thumbnail/`
        );
        if (response.data) {
          const imageUrl =
            constants.BACKEND_ADDRESS +
            response.data.image.substring(1, response.data.image.length);
          setMainImage(imageUrl);
        }
      } catch (error) {
        console.error("Error fetching main image:", error);
      } finally {
        setImageLoading(false);
      }
    };

    const checkIsSeller = () => {
      const username = localStorage.getItem("username");
      const userType = localStorage.getItem("userType");
      if (userType === "Seller" && username === constants.SELLER) {
        setIsSeller(true);
      }
    };

    const checkBidStatus = async () => {
      const username = localStorage.getItem("username");
      const userType = localStorage.getItem("userType");
      if (!username || userType === "Seller") return;
      if (username && item.highestBidder === username) {
        if (item.isSold) {
          setBidStatus("won");
        } else {
          setBidStatus("winning");
        }
      } else {
        try {
          const response = await axiosInstance.get(
            `${constants.SELLER}/is-losing-item/${item.id}/`
          );
          if (response.data.message === "Losing") {
            setBidStatus("losing");
          }
        } catch (error) {
          console.error("Error checking bid status:", error);
        }
      }
    };

    fetchMainImage();
    checkIsSeller();
    checkBidStatus();
  }, [item, loading]);

  if (loading) {
    return (
      <div className="inventory-item-container loading">
        <div className="main-image loading-placeholder"></div>
        <div className="divider-line"></div>
        <div className="item-container-details">
          <div className="loading-placeholder loading-text"></div>
          <div className="loading-placeholder loading-price"></div>
        </div>
      </div>
    );
  }

  const itemContent = (
    <div className={`inventory-item-content-container ${bidStatus}`}>
      {imageLoading ? (
        <div className="main-image container">
          <div className="main-image loading-placeholder"></div>
        </div>
      ) : (
        mainImage && (
          <div className="main-image container">
            <img
              className="main-image"
              src={mainImage}
              alt={`Item ${item.id} Main Image`}
            />
          </div>
        )
      )}
      <div className="divider-line" />
      <div className="item-container-details">
        <div className="item-title-container">
          <h2>{item.name}</h2>
        </div>
        <div className="item-price-container">
          <span>${item.highestBid || item.startPrice}</span>
          {!isSeller && (
            <span className={`item-status-container ${bidStatus}`}>
              {bidStatus === "winning"
                ? "   Winning"
                : bidStatus === "losing"
                ? "   Losing"
                : bidStatus === "won"
                ? "   Won"
                : ""}
            </span>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`inventory-item-container ${isSelected ? "selected" : ""}`}
      onClick={onItemClick}
      style={{
        cursor: isSelecting ? "pointer" : "default",
        opacity: isSelecting && !isSelected ? 0.5 : 1,
      }}
    >
      {isSelecting ? (
        <div className="item-content">{itemContent}</div>
      ) : (
        <Link
          to={`/view-item/${item.id}`}
          className="item-link"
          state={{
            from: "ViewAuction",
            auctionId: auctionId,
            fromPage: fromPage,
          }}
        >
          {itemContent}
        </Link>
      )}
    </div>
  );
};

export default InventoryItemContainer;
