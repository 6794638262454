import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import AuctionsPage from "./Components/Auction/Auctions";
import Footer from "./Components/Common/Footer";
import Home from "./Components/Front Page/Home";
import Inventory from "./Components/Inventory/Inventory";
import Navbar from "./Components/Common/Navbar";
import LoginPage from "./Components/Auth/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import AddItem from "./Components/Inventory/AddItem";
import ViewItem from "./Components/Inventory/ViewItem";
import AddAuction from "./Components/Auction/AddAuction";
import ViewAuction from "./Components/Auction/ViewAuction";
import Register from "./Components/Auth/Register";
import Sales from "./Components/Inventory/Sales";
import PickUpDetails from "./Components/Common/PickUpDetails";
import BuyerAccountPage from "./Components/Common/BuyerAccountPage";
import EditItem from "./Components/Inventory/EditItem";
import MyBids from "./Components/Common/MyBids";
import TermsPage from "./Components/Common/Terms";
import PasswordResetRequest from "./Components/Auth/PasswordResetRequest";
import PasswordResetConfirm from "./Components/Auth/PasswordResetConfirm";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedUserType = localStorage.getItem("userType");
    if (token) {
      setIsLoggedIn(true);
      setUserType(storedUserType); // Get the user type from local storage
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <div className="home">
          <Navbar isLoggedIn={isLoggedIn} />
          <Routes>
            <Route
              path="/login"
              element={<LoginPage setIsLoggedIn={setIsLoggedIn} />}
            />
            <Route path="/auctions" element={<AuctionsPage />} />
            <Route path="/" element={<Home />} />
            <Route path="/view-item/:itemId" element={<ViewItem />} />
            <Route path="/pick-up-details" element={<PickUpDetails />} />
            <Route
              path="/auction-contents/:auctionId"
              element={<ViewAuction />}
            />
            <Route
              path="/terms"
              element={<TermsPage />}
            />
            <Route path="/register" element={<Register />} />
            <Route path="/password-reset" element={<PasswordResetRequest />} />
            <Route
              path="/reset-password/:uid/:token"
              element={<PasswordResetConfirm />}
            />
            {isLoggedIn && userType === "Seller" && (
              <>
                <Route path="/inventory" element={<Inventory />} />
                <Route path="/add-item" element={<AddItem />} />
                <Route path="/edit-item/:itemId" element={<EditItem />} />
                <Route path="/sales" element={<Sales />} />
                <Route path="/add-auction" element={<AddAuction />} />
                <Route path="/account" element={<BuyerAccountPage />} />
              </>
            )}
            {isLoggedIn && userType === "Buyer" && (
              <>
                <Route path="/account" element={<BuyerAccountPage />} />
                <Route path="/my-bids" element={<MyBids />} />
              </>
            )}
          </Routes>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
