import React from 'react';
import "./Terms.css";

const TermsContent =
  (<div>
    <h2>Terms and Conditions</h2>
    <ol>
      <li>
        <strong>Registration: </strong>
        To access JMJ Auctions, register for a free account. This will allow you to bid on items within auctions.
        You must be 18 or older. By registering, you confirm that your information is true and accurate,
        with the risk of suspension for false details. No membership fee is required. A credit card is required
        at signup to allow you to participate in auctions.
      </li>
      <li>
        <strong>Public Online Auction: </strong>
        The highest bid on an item when its auction ends wins. By bidding, you agree to JMJ Auctions's
        terms and conditions. Your bid is a binding agreement to purchase the item if you win. This is a
        public auction, not a retail service. All sales are final, "as is," and without warranty. No
        returns or refunds unless specified in our return policy.
      </li>
      <li>
        <strong>Auction Format: </strong>
        This is an internet-only auction. All bidding is online. No bids can be placed in person. You are
        able to place bids on as many items as you want in each auction. When the end date of an auction
        arrives, all items are sold to their highest bidders.
      </li>
      <li>
        <strong>Bidding: </strong>
        Be careful when bidding. Your bid is a binding agreement to purchase at that price. Bidding starts
        at the specified start price and can be increased by $1 increments. Review your bids immediately.
        If you make an error (e.g missing a decimal point), contact us in writing immediately. We will not
        reverse bids after the auction has ended. NO EXCEPTIONS.
      </li>
      <li>
        <strong>Bid Increment System: </strong>
        Our bid increment system helps prevent erronous bidding and keeps increments at an amount that is
        roughly proportional to the current highest bid.

        These are the different increments at different highest bid prices:
        <ul>
          <li>$1 to $20 - Minimum $1 increment, maximum $20 increment. </li>
          <li>$21.00 to $50.00 - Minimum 10% (rounded down) increment.</li>
          <li>$51.00 and up - Minimum $5 increment.</li>
        </ul>
        Note: all items prevent you from bidding more than double the current highest bid unless they
        are under $20 dollars. This is to ensure no extremely large accidental bids happen. This does NOT
        mean we are responsible for any of your erronous bids.
      </li>
      <li>
        <strong>Sales Tax: </strong>
        Sales Tax in the amount of 8.25% of the total purchase is applied to the winning price of the item,
        when applicable. Those with tax-exempt status may contact us and provide the necessary documents to
        remove sales tax for applicable items. If applicable, sales tax is applied to the total order.
      </li>
      <li>
        <strong>Buyer’s Premium & Lot Handling Fee: </strong>
        Although most standard auctions include a Buyer's Premium paid at a percentage of the hammer price
        of an auction, JMJ Auctions is currently offering 0% Buyer's Premium and $0 Lot Handling Fee! Your
        payment will only include the bid price plus sales tax.
      </li>
      <li>
        <strong>Invoices & Payment: </strong>
        Invoices are emailed to winning bidders immediately after the auction ends. Check your spam folder
        and whitelist our email. Ensure you receive and review your invoice. The payment method on file for
        your account will automatically be charged for the full amount of your invoice when the auction ends.
      </li>
      <li>
        <strong>Unsuccessful payments: </strong>
        If we cannot charge your payment method, full payment is due within seven business days starting the
        day after the auction closes. We accept cash or cards from: Visa, Mastercard, American Express, Discover. We do not
        accept checks or digital transfers (e.g., CashApp, Venmo, PayPal). Payments can be made online
        or in person at pickup. Failure to pay by the due date results in immediate suspension, with multiple
        infractions leading to permanent suspension. See Abandoned Items for more details.
      </li>
      <li>
        <strong>Pick-Up: </strong>
        Pick-up times and locations are posted on the auction details page of our auction site. Lots not
        picked up within 7 days are considered abandoned and will be resold in subsequent auctions with no
        refunds issued. We do not allow partial pick-ups, plan accordingly to ensure your vehicle is large
        enough to accommodate all of your auction items.
      </li>
      <li>
        <strong>Auction Preview: </strong>
        We are not currently providing in-person previews. If you have any questions about a lot, please
        reach out through our Contact Us page or look for the question mark icon on the lot detail page to
        request additional information. Please note that items are sold as-is and they are
        untested/uninspected unless otherwise specified in the title.
      </li>
      <li>
        <strong>As-Is Condition:</strong>
        Unless otherwise specified in the lot title, all lots are offered and sold "AS IS" condition with
        all faults known and unknown. All warranties, including implicit warranties, are explicitly
        disclaimed herein. Known defects will be added to the lot description, however not every item is
        tested and/or inspected. Some items may be in perfect condition (unused) and other items may be
        used and/or may have defects. Please refer to our return policy below.
      </li>
      <li>
        <strong>Photos & Images: </strong>
        The photos used on the auction site are photos taken on-site of the exact item you are purchasing.
        Make sure to inspect ALL AVAILABLE PHOTOS of the item you are bidding on and additional details of
        the actual items featured in the auction. Please refer to the details for information that might
        not be available just by viewing photos.
      </li>
      <li>
        <strong>Return Policy: </strong>
        All sales are final, with no returns or exchanges unless specified in the listing or lot detail
        page, or in cases of significant discrepancy as defined by the company. JMJ Auctions only accepts
        returns if the item is significantly not as described. This means there is a major difference
        between the item received and the auction description/photo. Significant discrepancy is determined
        solely by JMJ Auctions' staff and management on a case-by-case basis. For example, if a lot
        labeled "blender" contains shoes instead, it qualifies as significantly not as described. Variations
        in model, size, brand, or color are not considered significant discrepancies. No refunds will be
        given for items that have been accepted and left the JMJ Auctions building. Inspect items at pickup
        before leaving. If a significant discrepancy is found, you will receive a full refund, provided the
        item has not left the premises. Items must be picked up during the designated times; unclaimed items
        are considered abandoned with no refund issued.
      </li>
      <li>
        <strong>Abandoned Property: </strong>
        Any items not picked up within the specified pick-up times will be considered abandoned and the buyer
        will not receive a refund for the items. JMJ Auctions may, at their sole discretion, deem the
        item to have been abandoned by the user and the user will have no further rights with respect to the
        item.
      </li>
      <li>
        <strong>Suspension & Reinstatement Fee: </strong>
        If a User account is suspended for any reason, the User may be assessed an additional Reinstatement
        Fee up to one hundred ($100.00) dollars. At JMJ Auctions's sole discretion, a User account may
        be permanently suspended for repeat violations of the Terms and Conditions.
      </li>
      <li>
        <strong>Missing Items: </strong>
        The JMJ Auctions team works hard to ensure accuracy in auction pickup. However, it is the sole
        responsibility of the customer to verify that all items are included in your order pickup. You
        may inventory items in the lobby or as you are loading them into the car. A copy of your invoices
        is available upon request. If you get home and discover an item is missing, you may reach out to
        our team. We cannot make any guarantees once your order has left the building, however we will make
        every effort to locate your item. We cannot guarantee refunds for missing items once your order has
        left the property.
      </li>
      <li>
        <strong>Right to Pull Lots: </strong>
        We reserve the right to pull any lot for any reason before the close of the auction. Any bids on
        the pulled lot will be nullified and the top bidder will not be charged.
      </li>
      <li>
        <strong>Discounts: </strong>
        Coupons, Discounts, and Credits may be offered to various customers via promotion, benefit, or
        advertisement.
      </li>
      <li>
        <strong>Valid Credit/Debit Card on File: </strong>
        Bidders must maintain a valid payment method on their bidding account. Invalid or fraudulent credit
        cards may result in a buyer being banned from participating in future auctions with JMJ Auctions.
      </li>
      <li>
        <strong>Payment Method Pre-Authorization: </strong>
        When adding a payment method to the auction platform, an authorization (also known as a "hold" or
        "pre-authorization") credit in the amount of $1 may appear on the credit card. This amount is not
        actually charged to the card, but it is held against the card's available credit so that the amount
        remains available to the authorizing merchant to charge. Depending on the credit card's issuing bank,
        an authorization will expire (automatically release) after 1-8 business days. An authorization might
        show on your card statement or mobile app as a "Pending" charge, or it might not show at all.
      </li>
      <li>
        <strong>Account Suspension: </strong>
        JMJ Auctions reserves the right to suspend (ban) any account and/or user who fails to abide by the
        terms and conditions stated herein. This suspension will apply to all bidder account current
        associated with the user and all future bidder accounts. Account suspensions may include, but are
        not limited to, failure to pick up items within seven days and failure to pay invoices within seven
        days. If you have questions about an account suspension, please call (512) 996-5120 or visit us in
        person during our regular pickup hours.
      </li>
      <li>
        <strong>Bidders' Responsibility: </strong>
        You are responsible for all bids made from your account. If you believe that an unauthorized person
        has gained access to your account, you must notify JMJ Auctions immediately by sending a
        message. You must also report the incident to the relevant law enforcement authorities and send
        JMJ Auctions a copy of any resulting police report. You understand and agree that if you do not
        promptly report any suspected fraud to JMJ Auctions and to the relevant law enforcement authorities,
        you will be held personally responsible for all purchases made using your account and bidder number.
        Bidding can be fun, but please bid responsibly. If you feel you have an addiction or problem with
        gambling, please contact The National Problem Gambling Helpline at 1-800-522-4700.
      </li>
      <li>
        <strong>No Credit Card Chargebacks: </strong>
        Bidders agree to NOT, under any circumstances, initiate a credit card chargeback with respect to any
        charges. Each Bidder further agrees that if, in violation of these Terms and Conditions, a credit
        card chargeback is initiated by the Bidder, these Terms and Conditions shall be conclusive evidence
        of the Bidder's (i) agreement to not initiate a credit card chargeback and (ii) waiver of any rights
        to initiate a credit card chargeback. Each Bidder acknowledges and agrees that on the Auctioneer's
        presentation of these Bidder Terms and Conditions to the bank, credit card company, or other
        financial institution that issued the credit card on which the disputed charges were made, such
        bank, credit card company, or other financial institution shall deny any credit card chargeback and
        shall re-credit all amounts to the account of the Auctioneer. If a Bidder initiates a credit card
        chargeback, such Bidder, even if successful, shall remain liable for all purchases made at the
        auction and shall be responsible for all costs and expenses, including any attorneys' fees, storage
        fees, packaging and handling fees, transportations fees, and/or disposal fees, etc., incurred by or
        on behalf of the Auctioneer or Seller in challenging the credit card chargeback. Each Bidder agrees
        that the agreement to not initiate a credit card chargeback is a condition to the issuance of a
        Bidder Number to the Bidder and is being made as an inducement for the Auctioneer to accept the
        Bidder's Registration and to issue a Bidder Number.
      </li>
      <li>
        <strong>Errors & Discrepancies: </strong>
        All titles and descriptions are believed to be accurate and written to the best of our knowledge
        but may contain inaccuracies or errors. Titles and descriptions are not warranted nor do they
        imply any warranties. Measurements and counts are approximate. Questions are welcome and encouraged
        before bidding. Bidding on an item is a binding agreement to purchase that item irrespective of any
        errors in titles or descriptions. All items have absolutely no guarantee whatsoever. Most items
        are not opened or inspected for accuracy or damages. These items you buy at your own risk. See our
        return policy for additional information.
      </li>
      <li>
        <strong>Agreement to Terms: </strong>
        By bidding in this auction, you agree to all the terms posted for this auction. JMJ Auctions will
        not be held liable for any software or software malfunction discrepancies.
      </li>
      <li>
        <strong>Privacy Policy: </strong>
        We respect and are committed to protecting your privacy and online security. We may collect information
        about your device, web browser, IP address, time zones, and cookies. We may also collect information
        about how you interact with the website. In terms of personal information, we do not store your name.
        Only your username, email, and password are stored a secure way, as well as a customer ID associated
        with our account in Square, our 3rd party payment platform.
      </li>
      <li>
        <strong>Security Policy: </strong>
        Our Secure Sockets Layer (SSL) software is the industry standard and among the best software available
        today for secure commerce transactions. It encrypts all of your personal information, including
        credit card number, name, and address, so that it cannot be read over the internet. All transactions
        are processed using Square, our 3rd party payment processor. Your payment information is never
        stored on our servers or on our software platform.
      </li>
      <li>
        <strong>Limitation of Liability: </strong>
        In no event shall JMJ Auctions be liable for any damages of any kind arising from (a) any
        injury to any person or property caused by the equipment, items, products, or goods or (b) defects
        in such equipment, items, products, or goods on any theory of liability including without limitation
        product liability except only in the case of personal injury where and to the extent that applicable
        law requires such liability. In addition, in no event shall JMJ Auctions or seller be liable for
        any special, incidental or consequential damages that are directly or indirectly related to the use
        of, or the inability to use, the service, the website or its content including without limitation
        the inspection report and materials and functions related thereto, including without limitation,
        loss of revenue, anticipated profits, lost business or lost sales, even if JMJ Auctions or an
        authorized representative thereof has been advised of the possibility of such damages. Some
        jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential
        damages so some of the above limitations may not apply to certain users. In no event shall the total
        liability of JMJ Auctions to you for all damages, losses, and causes of action (whether in
        contract or tort, including, but not limited to, negligence, product liability or otherwise) arising
        from this agreement or your use of the website and the service exceed, in the aggregate, $100.00. The
        foregoing limitation shall apply and survive notwithstanding any failure of essential purpose of any
        remedy.
      </li>
    </ol>
  </div>)

const TermsPage = () => {
  return (
    <div className="terms-text">
      {TermsContent}
    </div>
  );
};

export default TermsPage;
