import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import "./Auth.css"; // Ensure this is included for consistent styling

const PasswordResetConfirm = () => {
    const { uid, token } = useParams(); // Get the uid and token from the URL
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false); // Track if it's an error message
    const [shake, setShake] = useState(false); // For shake effect in case of an error
    const [loading, setLoading] = useState(false);
    const [passwordReset, setPasswordReset] = useState(false); // Track if password has been reset

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return; // Prevent multiple submissions
        setLoading(true);
        setShake(false);
        setIsError(false); // Reset error state

        try {
            const response = await axiosInstance.post(`/password-reset-confirm/${uid}/${token}/`, {
                password,
            });
            setMessage("Your password has been successfully reset.");
            setPasswordReset(true); // Hide the form after successful password reset
        } catch (error) {
            setMessage("This password reset link is expired or invalid.");
            setIsError(true); // Set error state
            setShake(true); // Trigger shake effect
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="auth-container">
            <div className="auth-box">
                {loading && <div className="loading-bar"></div>}
                <h2>Reset Your Password</h2>
                {message && (
                    <p className={`${isError ? 'error-message' : ''} ${shake ? 'shake' : ''} ${passwordReset ? 'success-message' : ''}`}>
                        {message}
                    </p>
                )}
                {!passwordReset && ( // Hide form if password is reset
                    <form onSubmit={handleSubmit}>
                        <div className="auth-form-group">
                            <label>New Password</label>
                            <input
                                type="password"
                                placeholder="Enter new password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                disabled={loading}
                            />
                        </div>
                        <button type="submit" className="auth-button" disabled={loading}>
                            Reset Password
                        </button>
                    </form>
                )}

                {passwordReset && ( // Show link to login page after successful reset
                    <div className="auth-link-container">
                        <Link to="/login" className="auth-link">Go back to login</Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PasswordResetConfirm;
