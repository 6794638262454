import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import constants from "../../Constants";
import "./Auctions.css"; // Import the CSS file for styling
import AddTestAuction from "./AddTestAuction.js";

const Auctions = () => {
  const [auctions, setAuctions] = useState([]);
  const [isSeller, setIsSeller] = useState(false);

  useEffect(() => {
    const fetchAuctions = async () => {
      try {
        const [activeResponse, futureResponse] = await Promise.all([
          axiosInstance.get(`${constants.SELLER}/active-auctions/`),
          axiosInstance.get(`${constants.SELLER}/future-auctions/`),
        ]);

        // Combine the data from both responses
        const combinedAuctions = [
          ...activeResponse.data,
          ...futureResponse.data,
        ];

        // Set the combined data to the state
        setAuctions(combinedAuctions);
      } catch (error) {
        console.error("Error fetching auctions:", error);
      }
    };

    // Check if user is a seller
    const token = localStorage.getItem("token");
    if (token) {
      const username = localStorage.getItem("username");
      const userType = localStorage.getItem("userType");
      if (userType === "Seller" && username === constants.SELLER) {
        setIsSeller(true);
      }
    }

    fetchAuctions();
  }, []);

  // Function to format the date
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  // Define the AuctionContainer component
  const AuctionContainer = ({ auction }) => {
    const now = new Date().getTime();
    const startTime = new Date(auction.startTime).getTime();

    return (
      <div className="auction-container">
        <Link to={`/auction-contents/${auction.id}`} className="auction-link">
          <h3>{auction.name}</h3>
          <p>
            {startTime > now ? "Starts: " : "Ends: "}
            {formatDate(startTime > now ? auction.startTime : auction.endTime)}
          </p>
        </Link>
      </div>
    );
  };

  return (
    <div className="auctions-page">
      <div className="inventory-title-container">
        <h3>Auctions</h3>
        {isSeller && (
          <div>
            <Link to="/add-auction" className="add-auction-button">
              Add Auction
            </Link>
          </div>
        )}
      </div>
      <hr className="title-divider" />
      <div className="auctions-container">
        {auctions.map((auction) => (
          <AuctionContainer key={auction.id} auction={auction} />
        ))}
      </div>
    </div>
  );
};

export default Auctions;
