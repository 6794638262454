import React, { useState } from "react";
import axiosInstance from '../../axiosInstance';
import "./Auth.css";

const PasswordResetRequest = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false); // Track if it's an error message
    const [shake, setShake] = useState(false); // For shaking effect
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false); // Track if email was sent successfully

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return; // Prevent multiple submissions
        setLoading(true);
        setShake(false);
        setIsError(false); // Reset error state

        try {
            const response = await axiosInstance.post("/password-reset/", { email });
            setMessage("Password Reset Email Sent"); // Display success message
            setEmailSent(true); // Hide the email input
        } catch (error) {
            setMessage("This email is not associated with an account.");
            setIsError(true); // Set error state
            setShake(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="auth-container">
            <div className={`auth-box`}>
                {loading && <div className="loading-bar"></div>}
                <h2>Reset Password</h2>
                {message && (
                    <p className={`${isError ? 'error-message' : ''} ${shake ? 'shake' : ''} ${emailSent ? 'success-message' : ''}`}>
                        {message}
                    </p>
                )}
                {!emailSent && ( // Hide form if email was sent successfully
                    <form onSubmit={handleSubmit}>
                        <div className="auth-form-group">
                            <label>Email Address</label>
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                disabled={loading}
                            />
                        </div>
                        <button type="submit" className="auth-button" disabled={loading}>
                            Send Password Reset Email
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default PasswordResetRequest;
