import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import InventoryItemContainer from "./InventoryItemContainer";
import "./Inventory.css";

const MAX_ITEMS_PER_PAGE = 12;

const Sales = () => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [salesSize, setSalesSize] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchSales = async (startIdx, endIdx, query = "") => {
    try {
      const response = await axiosInstance.get(
        `auctioneer/sales/${startIdx}/${endIdx}/`,
        {
          params: { search: query },
        }
      );
      setInventoryItems(response.data);
    } catch (error) {
      console.error("Error fetching inventory:", error);
    }
  };

  const fetchSalesSize = async (query = "") => {
    try {
      const response = await axiosInstance.get("auctioneer/sales-count/", {
        params: { search: query },
      });
      const size = response.data;
      setSalesSize(size);
      const pageCount = Math.ceil(size / MAX_ITEMS_PER_PAGE);
      setPageCount(pageCount);
    } catch (error) {
      console.error("Error fetching inventory size:", error);
    }
  };

  const toggleSelectMode = () => {
    setIsSelecting(!isSelecting);
    setSelectedItems([]);
  };

  const handleItemClick = (itemId) => {
    if (isSelecting) {
      setSelectedItems((prevSelected) =>
        prevSelected.includes(itemId)
          ? prevSelected.filter((id) => id !== itemId)
          : [...prevSelected, itemId]
      );
    }
  };

  const handleDeleteItems = async () => {
    const confirmed = window.confirm(
      "Are you sure you'd like to delete these items?"
    );
    if (!confirmed) return;

    try {
      await axiosInstance.delete("auctioneer/delete-items/", {
        data: {
          itemIds: selectedItems,
        },
      });
      toggleSelectMode(); // Reset selection mode
      window.location.reload(); // Reload the page after deleting items
    } catch (error) {
      console.error("Error deleting items:", error);
    }
  };

  useEffect(() => {
    fetchSalesSize();
  }, []);

  useEffect(() => {
    if (salesSize > 0) {
      fetchSales(0, Math.min(MAX_ITEMS_PER_PAGE, salesSize), searchQuery);
    }
  }, [salesSize]);

  const handlePageChange = (pageIndex) => {
    setSelectedPage(pageIndex);
    const startIdx = MAX_ITEMS_PER_PAGE * pageIndex;
    const endIdx = Math.min((pageIndex + 1) * MAX_ITEMS_PER_PAGE, salesSize);
    fetchSales(startIdx, endIdx, searchQuery);
  };

  const handleSearch = () => {
    fetchSalesSize(searchQuery);
    handlePageChange(0);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const renderPagination = () => {
    return (
      <div className="pagination">
        <span>Page </span>
        <div className="custom-select">
          <span className="selected-page">{selectedPage + 1}</span>
          <span> of {pageCount} </span>
          <span className="arrow">▼</span>
          <select
            value={selectedPage}
            onChange={(e) => handlePageChange(parseInt(e.target.value))}
            className="page-select"
          >
            {Array.from({ length: pageCount }, (_, index) => (
              <option key={index} value={index}>
                {index + 1}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  return (
    <div className="inventory-page">
      <div className="inventory-title-container">
        <h3>Completed Sales</h3>
        <button onClick={toggleSelectMode} className="select-button">
          {isSelecting ? "Cancel" : "Select"}
        </button>
      </div>
      <hr className="title-divider" />
      {isSelecting && (
        <div className="action-buttons">
          <button onClick={handleDeleteItems} className="action-button">
            Delete
          </button>
        </div>
      )}
      {!isSelecting && (
        <div className="search-layer">
          <div className="search-input-container">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleSearchKeyPress}
              placeholder="Search items..."
              className="search-input"
            />
            <span className="search-icon" onClick={handleSearch}>
              ⌕
            </span>
          </div>
          {window.innerWidth > 800 && renderPagination()}
        </div>
      )}
      <div className="inventory-grid">
        {inventoryItems.length === 0 ? (
          <p className="empty-inventory-message">The Inventory is Empty</p>
        ) : (
          inventoryItems.map((item) => (
            <InventoryItemContainer
              key={item.id}
              item={item}
              isSelecting={isSelecting}
              isSelected={selectedItems.includes(item.id)}
              onItemClick={() => handleItemClick(item.id)}
              fromPage={"/sales"}
            />
          ))
        )}
      </div>
      {renderPagination()}
    </div>
  );
};

export default Sales;
