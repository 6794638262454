import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosInstance';
import InventoryItemContainer from './InventoryItemContainer';
import { Link } from 'react-router-dom';
import './Inventory.css';
import constants from '../../Constants';

const MAX_ITEMS_PER_PAGE = 12;

const Inventory = () => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [auctions, setAuctions] = useState([]);
  const [showAuctionDropdown, setShowAuctionDropdown] = useState(false);
  const [selectedAuction, setSelectedAuction] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [inventorySize, setInventorySize] = useState(0);
  const [searchQuery, setSearchQuery] = useState('')

  const fetchItems = async (startIdx, endIdx, query = '') => {
    try {
      const response = await axiosInstance.get(`auctioneer/items/${startIdx}/${endIdx}/`, {
        params: { search: query }
      });
      setInventoryItems(response.data);
    } catch (error) {
      console.error('Error fetching inventory:', error);
    }
  };

  const fetchInventorySize = async (query = '') => {
    try {
      const response = await axiosInstance.get('auctioneer/unsold-item-count/', {
        params: { search: query }
      });
      const size = response.data;
      setInventorySize(size);
      const pageCount = Math.ceil(size / MAX_ITEMS_PER_PAGE);
      setPageCount(pageCount);
    } catch (error) {
      console.error('Error fetching inventory size:', error);
    }
  };

  const fetchAuctions = async () => {
    try {
      const [activeAuctions, futureAuctions] = await Promise.all([
        axiosInstance.get(`${constants.SELLER}/active-auctions/`),
        axiosInstance.get(`${constants.SELLER}/future-auctions/`)
      ]);

      setAuctions([...activeAuctions.data, ...futureAuctions.data]);
    } catch (error) {
      console.error('Error fetching auctions:', error);
    }
  };

  useEffect(() => {
    fetchInventorySize(searchQuery);
    fetchAuctions();
  }, []);

  useEffect(() => {
    if (inventorySize > 0) {
      fetchItems(0, Math.min(MAX_ITEMS_PER_PAGE, inventorySize), searchQuery);
    }
  }, [inventorySize]);

  const toggleSelectMode = () => {
    setIsSelecting(!isSelecting);
    setSelectedItems([]);
    setShowAuctionDropdown(false);
  };

  const handleItemClick = (itemId) => {
    if (isSelecting) {
      setSelectedItems((prevSelected) =>
        prevSelected.includes(itemId)
          ? prevSelected.filter((id) => id !== itemId)
          : [...prevSelected, itemId]
      );
    }
  };

  const handleAddItemsToAuction = () => {
    setShowAuctionDropdown(true);
  };

  const handleConfirmAddToAuction = async () => {
    if (!selectedAuction) return;
    try {
      await axiosInstance.post(`auctioneer/add-items-to-auction/${selectedAuction}/`, {
        itemIds: selectedItems
      });
      setShowAuctionDropdown(false);
      toggleSelectMode(); // Reset selection mode
      window.location.reload(); // Reload the page after adding items to auction
    } catch (error) {
      console.error('Error adding items to auction:', error);
    }
  };

  const handleDeleteItems = async () => {
    const confirmed = window.confirm("Are you sure you'd like to delete these items?");
    if (!confirmed) return;

    try {
      await axiosInstance.delete('auctioneer/delete-items/', {
        data: {
          itemIds: selectedItems
        }
      });
      toggleSelectMode(); // Reset selection mode
      window.location.reload(); // Reload the page after deleting items
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  };

  const handlePageChange = (pageIndex) => {
    setSelectedPage(pageIndex);
    const startIdx = MAX_ITEMS_PER_PAGE * pageIndex;
    const endIdx = Math.min((pageIndex + 1) * MAX_ITEMS_PER_PAGE, inventorySize);
    fetchItems(startIdx, endIdx, searchQuery);
  };

  const handleSearch = () => {
    fetchInventorySize(searchQuery);
    handlePageChange(0);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
        handleSearch();
    }
  };

  const renderPagination = () => {
        return (
            <div className="pagination">
                <span>Page </span>
                <div className="custom-select">
                    <span className="selected-page">{selectedPage + 1}</span>
                    <span> of {pageCount} </span>
                    <span className="arrow">▼</span>
                    <select
                        value={selectedPage}
                        onChange={(e) => handlePageChange(parseInt(e.target.value))}
                        className="page-select"
                    >
                        {Array.from({ length: pageCount }, (_, index) => (
                            <option key={index} value={index}>
                                {index + 1}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        );
    };
    

  return (
    <div className="inventory-page">
      <div className="inventory-title-container">
        <h3>Inventory</h3>
        <div className="title-buttons">
          <Link to="/add-item" className="add-item-button">Add Item</Link>
          <button onClick={toggleSelectMode} className="select-button">
            {isSelecting ? 'Cancel' : 'Select'}
          </button>
        </div>
      </div>
      <hr className="title-divider" />
      {isSelecting && (
        <div className="action-buttons">
          <button onClick={handleAddItemsToAuction} className="action-button">Auction</button>
          <button onClick={handleDeleteItems} className="action-button">Delete</button>
        </div>
      )}
      {!isSelecting && (
        <div className="search-layer">
          <div className="search-input-container">
              <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyPress={handleSearchKeyPress}
                  placeholder="Search items..."
                  className="search-input"
              />
              <span className="search-icon" onClick={handleSearch}>⌕</span>
          </div>
          {window.innerWidth > 800 && renderPagination()}
        </div>
      )}
      {showAuctionDropdown && (
        <div className="auction-dropdown">
          <select value={selectedAuction} onChange={(e) => setSelectedAuction(e.target.value)}>
            <option value="" disabled>Select an auction</option>
            {auctions.map((auction) => (
              <option key={auction.id} value={auction.id}>
                {auction.name}
              </option>
            ))}
          </select>
          <button onClick={handleConfirmAddToAuction} className="confirm-button">Confirm</button>
        </div>
      )}
      <div className="inventory-grid">
        {inventoryItems.length === 0 ? (
          <p className="empty-inventory-message">The Inventory is Empty</p>
        ) : (
          inventoryItems.map(item => (
            <InventoryItemContainer
              key={item.id}
              item={item}
              isSelecting={isSelecting}
              isSelected={selectedItems.includes(item.id)}
              onItemClick={() => handleItemClick(item.id)}
              fromPage={'/inventory'}
            />
          ))
        )}
      </div>
      {renderPagination()}
    </div>
  );
};

export default Inventory;
