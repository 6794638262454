import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import './AddAuction.css';

const AddAuction = () => {
    const [auctionDetails, setAuctionDetails] = useState({
        name: '',
        startTime: '',
        endTime: ''
    });
    const [uploadStatus, setUploadStatus] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAuctionDetails({ ...auctionDetails, [name]: value });
    };

    const convertToUTC = (localDateTime) => {
        const date = new Date(localDateTime);
        return date.toISOString();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (new Date(auctionDetails.endTime) <= new Date(auctionDetails.startTime)) {
            setUploadStatus('End time must be greater than start time.');
            return;
        }

        const auctionDetailsInUTC = {
            ...auctionDetails,
            startTime: convertToUTC(auctionDetails.startTime),
            endTime: convertToUTC(auctionDetails.endTime)
        };

        try {
            await axiosInstance.post('auctioneer/post-auction/', auctionDetailsInUTC);
            setUploadStatus('Auction created successfully!');
            navigate('/auctions');
        } catch (error) {
            console.error('Error creating auction:', error.response.data);
            setUploadStatus('Error creating auction. Please try again.' + JSON.stringify(error.response.data));
        }
    };

    return (
        <div className="add-auction-page">
            <div className='inventory-title-container'>
                <h1>Add Auction</h1>
            </div>
            <hr className='title-divider' />
            <div className="details-section">
                <h2>Details</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Auction Name:
                        <input
                            type="text"
                            name="name"
                            value={auctionDetails.name}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    <label>
                        Start Time:
                        <input
                            type="datetime-local"
                            name="startTime"
                            value={auctionDetails.startTime}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    <label>
                        End Time:
                        <input
                            type="datetime-local"
                            name="endTime"
                            value={auctionDetails.endTime}
                            onChange={handleInputChange}
                            required
                        />
                    </label>
                    <button className="add-item-button">Submit</button>
                </form>
                {uploadStatus && <p>{uploadStatus}</p>}
            </div>
        </div>
    );
};

export default AddAuction;
