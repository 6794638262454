import React from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import "./AddAuction.css"; // Make sure this is the correct path

const AddTestAuction = () => {
  const navigate = useNavigate();

  const convertToUTC = (localDateTime) => {
    const date = new Date(localDateTime);
    return date.toISOString();
  };

  const handleTestAuction = async () => {
    const currentTime = new Date();
    const endTime = new Date(currentTime.getTime() + 1 * 10 * 1000);

    const testAuctionDetails = {
      name: "Test Auction 2",
      startTime: convertToUTC(currentTime),
      endTime: convertToUTC(endTime),
    };

    try {
      const auctionResponse = await axiosInstance.post(
        "auctioneer/post-auction/",
        testAuctionDetails
      );
      const auctionId = auctionResponse.data.id;
      //alert("Test auction created successfully!");

      const testItems = [
        {
          name: "Test Item 1",
          condition: "New",
          description: "Description for test item 1",
          startPrice: "100.00",
          auction: auctionId,
        },
        {
          name: "Test Item 2",
          condition: "Used",
          description: "Description for test item 2",
          startPrice: "200.00",
          auction: auctionId,
        },
      ];

      for (const item of testItems) {
        await axiosInstance.post("auctioneer/post-item/", item);
      }

      await axiosInstance.get("auctioneer/addTestBidders/");

      //alert("Test items and bidders added successfully!");
      navigate("/auctions");
    } catch (error) {
      console.error(
        "Error creating test auction or adding items:",
        error.response?.data
      );
      //   alert(
      //     "Error creating test auction or adding items. Please try again." +
      //       JSON.stringify(error.response?.data)
      //   );
    }
  };

  return (
    <div className="add-test-auction-page">
      <button className="add-auction-button" onClick={handleTestAuction}>
        Add Test Auction
      </button>
    </div>
  );
};

export default AddTestAuction;
