import React from 'react';
import './PickUpDetails.css';
import { Link } from 'react-router-dom';

const PickUpDetails = () => {
    return (
        <div className="pickup-details-page">
            <div className="inventory-title-container">
                <h3>Pick Up Details</h3>
            </div>
            <hr className="title-divider" />
            <div className="pickup-page-split">
                <div className="pickup-content">
                    <p>Please make sure to review the pick-up details carefully before bidding. Here are some key points:</p>
                    <ul>
                        <li><strong>Address:</strong> 2013 Wells Branch Pkwy Suite 307, Austin, TX 78728. </li>
                        <li>
                            We are located at 2013 Wells Branch Pkwy Suite 307, Austin, TX 78728.
                            To reach our store, you can enter the parking lots through the south side of Wells Branch Pkwy,
                            and head to the further buildings at the end of the complex, past the Kumon and by the 413 Fitness.
                        </li>
                        <li><strong>Pickup Times: </strong>
                            <div className='pickup-time-container-pickup-page'>
                                <div className='pickup-time-days'>
                                    <p>Monday-Friday: </p>
                                    <p>Saturday-Sunday: </p>
                                </div>
                                <div className='pickup-time-hours'>
                                    <p>4pm-7pm</p>
                                    <p>1pm-5pm</p>
                                </div>
                            </div>
                        </li>
                        <li><strong>Contact:</strong> (512) 996-5120 </li>
                        <li><strong>Requirements:</strong> Bring a valid ID and a copy of your winning bid confirmation email. </li>
                        <li><strong>Note:</strong> Items not picked up within 7 business days will be forfeited.</li>

                        <span>Please view the </span><Link to="/terms" className="intro-links">terms and conditions </Link><span>for more information about online auctioning logistics.</span>
                    </ul>
                </div>
                {/* <div className='location-images-section'>
                    <h1> Put Image Here </h1>
                </div> */}
            </div>
        </div>
    );
};

export default PickUpDetails;
