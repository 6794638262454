import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import constants from "../../Constants";
import "./ViewAuction.css";
import InventoryItemContainer from "../Inventory/InventoryItemContainer";
import CountdownTimer from "./CountdownTimer"; // Import the CountdownTimer component

const MAX_ITEMS_PER_PAGE = 12;

const ViewAuction = () => {
  const { auctionId } = useParams();
  const [items, setItems] = useState([]);
  const [auction, setAuction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSeller, setIsSeller] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [auctionSize, setAuctionSize] = useState(0);
  const [auctionDeleteFailure, setAuctionDeleteFailure] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const fetchItems = async (startIdx, endIdx, query = "") => {
    try {
      const response = await axiosInstance.get(
        `${constants.SELLER}/auction-items/${auctionId}/${startIdx}/${endIdx}/`,
        {
          params: { search: query },
        }
      );
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchAuctionDetails = async (query = "") => {
    try {
      const response = await axiosInstance.get(
        `${constants.SELLER}/auction-details/${auctionId}/`,
        {
          params: { search: query },
        }
      );
      setAuction(response.data);
      setAuctionSize(response.data.queryItemSize);
      setLoading(false);
      const pageCount = Math.ceil(
        response.data.queryItemSize / MAX_ITEMS_PER_PAGE
      );
      setPageCount(pageCount);
    } catch (error) {
      console.error("Error fetching auction details:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const username = localStorage.getItem("username");
      const userType = localStorage.getItem("userType");
      if (userType === "Seller" && username === constants.SELLER) {
        setIsSeller(true);
      }
    }

    fetchAuctionDetails();
  }, [auctionId]);

  useEffect(() => {
    if (auctionSize > 0) {
      fetchItems(0, Math.min(MAX_ITEMS_PER_PAGE, auctionSize), searchQuery);
    }
  }, [auctionSize]);

  const handleDeleteAuction = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you'd like to delete this auction?"
    );
    if (confirmDelete) {
      try {
        await axiosInstance.delete(`auctioneer/delete-auction/${auctionId}/`);
        navigate("/auctions");
      } catch (error) {
        console.error("Error deleting auction:", error);
        setAuctionDeleteFailure(
          "Error deleting auction: " + error.response.data.error
        );
      }
    }
  };

  const handlePageChange = (pageIndex) => {
    setSelectedPage(pageIndex);
    const startIdx = MAX_ITEMS_PER_PAGE * pageIndex;
    const endIdx = Math.min((pageIndex + 1) * MAX_ITEMS_PER_PAGE, auctionSize);
    fetchItems(startIdx, endIdx, searchQuery);
  };

  const handleSearch = () => {
    fetchAuctionDetails(searchQuery);
    handlePageChange(0);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const renderPagination = () => {
    return (
      <div className="pagination">
        <span>Page </span>
        <div className="custom-select">
          <span className="selected-page">{selectedPage + 1}</span>
          <span> of {pageCount} </span>
          <span className="arrow">▼</span>
          <select
            value={selectedPage}
            onChange={(e) => handlePageChange(parseInt(e.target.value))}
            className="page-select"
          >
            {Array.from({ length: pageCount }, (_, index) => (
              <option key={index} value={index}>
                {index + 1}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  return (
    <div className="view-auction-page">
      {loading ? (
        <p>Loading...</p>
      ) : auction ? (
        <>
          <div className="inventory-title-container">
            <h3>{auction.name}</h3>
            {isSeller && (
              <Link
                to={`/add-item`}
                state={{ from: "ViewAuction", auctionId: auctionId }}
                className="add-item-button"
              >
                Add Item
              </Link>
            )}
          </div>
          <hr className="title-divider" />
          {auction && (
            <CountdownTimer
              startTime={auction.startTime}
              endTime={auction.endTime}
              onEnd={() => alert("Auction has ended!")}
            />
          )}
          <div className="search-layer">
            <div className="search-input-container">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleSearchKeyPress}
                placeholder="Search items..."
                className="search-input"
              />
              <span className="search-icon" onClick={handleSearch}>
                ⌕
              </span>
            </div>
            {window.innerWidth > window.innerHeight && renderPagination()}
          </div>
          <div className="inventory-grid">
            {items.length > 0 ? (
              items.map((item) => (
                <InventoryItemContainer
                  key={item.id}
                  item={item}
                  auctionId={auctionId}
                />
              ))
            ) : (
              <p className="empty-auction-message">No items found.</p>
            )}
          </div>
          {renderPagination()}
          {isSeller && (
            <button
              onClick={handleDeleteAuction}
              className="delete-auction-button"
            >
              Delete Auction
            </button>
          )}
          <p>{auctionDeleteFailure}</p>
        </>
      ) : (
        <p>No auction found.</p>
      )}
    </div>
  );
};

export default ViewAuction;
