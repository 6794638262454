import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import "./FrontPageModules.css"; // Don't forget to import the CSS file
import { Link } from "react-router-dom";
import constants from "../../Constants.js";
import InventoryItemContainer from "../Inventory/InventoryItemContainer.js";

const HeroSection = () => {
  const [isSeller, setIsSeller] = useState(false);
  const [isBuyer, setIsBuyer] = useState(false);
  const [username, setUsername] = useState("");
  const [weeklyAuction, setWeeklyAuction] = useState(null);

  useEffect(() => {
    const retrievedUsername = localStorage.getItem("username");
    setUsername(retrievedUsername);
    const userType = localStorage.getItem("userType");

    if (userType === "Seller" && retrievedUsername === constants.SELLER) {
      setIsSeller(true);
    } else if (userType === "Buyer") {
      setIsBuyer(true);
    }

    const fetchAuctions = async () => {
      try {
        const [activeResponse, futureResponse] = await Promise.all([
          axiosInstance.get(`${constants.SELLER}/active-auctions/`),
          axiosInstance.get(`${constants.SELLER}/future-auctions/`),
        ]);

        // Combine the data from both responses
        const combinedAuctions = [
          ...activeResponse.data,
          ...futureResponse.data,
        ];

        const weeklyAuction = combinedAuctions.find((auction) =>
          auction.name.startsWith("Weekly Auction")
        );
        setWeeklyAuction(weeklyAuction);
      } catch (error) {
        console.error("Error fetching auctions:", error);
      }
    };

    fetchAuctions();
  }, []);

  return (
    <section className="hero">
      <div className="hero-content">
        <h1>JMJ Auctions</h1>
        <p>Bid on the Best Local Items Today</p>
        <div className="buttons">
          <Link
            to={
              weeklyAuction
                ? `/auction-contents/${weeklyAuction.id}`
                : "/auctions"
            }
            className="hero-button"
          >
            This Week's Auction
          </Link>
          {!(isSeller | isBuyer) && (
            <Link to="/register" className="hero-button secondary">
              Register to Bid
            </Link>
          )}
          {isBuyer && (
            <Link to="/my-bids" className="hero-button secondary">
              My Bids
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

const InfoContainer = () => {
  return (
    <section className="location-container">
      <div className="location-content">
        <h2>Location</h2>
        <p>
          We are located at 2013 Wells Branch Pkwy Suite 307, Austin, TX 78728.
          To reach our store, you can enter the parking lots through the south
          side of Wells Branch Pkwy, and head to the further buildings at the
          end of the complex, past the Kumon and by the 413 Fitness.
        </p>
        <Link
          to="https://www.google.com/maps/place/JMJ+Auctions/@30.4342312,-97.7024341,15z/data=!4m10!1m2!2m1!1sJmJ+Auctions!3m6!1s0x8644cf07a6593269:0xd29a63d8e768b055!8m2!3d30.4342312!4d-97.6833797!15sCgxKbUogQXVjdGlvbnOSARJlX2NvbW1lcmNlX3NlcnZpY2XgAQA!16s%2Fg%2F11w4q569fy?entry=ttu&g_ep=EgoyMDI0MDkwNC4wIKXMDSoASAFQAw%3D%3D"
          className="hero-button"
        >
          Google Maps
        </Link>
        <div className="pickup-info">
          <h4>Pickup Times</h4>
          <div className="pickup-time-container">
            <div className="pickup-time-days">
              <h6>Monday-Friday:</h6>
              <h6>Saturday-Sunday:</h6>
            </div>
            <div className="pickup-time-hours">
              <h6>4pm-7pm</h6>
              <h6>1pm-5pm</h6>
            </div>
          </div>
          <div className="buttons">
            <Link to="/pick-up-details" className="hero-button">
              More Pickup Details
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

const PopularItemsSection = () => {
  const [popularItems, setPopularItems] = useState([]);
  const [itemsToDisplay, setItemsToDisplay] = useState([]); // This will hold the cut array

  useEffect(() => {
    const fetchPopularItems = async () => {
      try {
        const response = await axiosInstance.get(
          `${constants.SELLER}/top-items/6/`
        );
        setPopularItems(response.data);
      } catch (error) {
        console.error("Error fetching popular items:", error);
      }
    };

    fetchPopularItems();
  }, []);

  useEffect(() => {
    const updateItemsToDisplay = () => {
      const width = window.innerWidth;
      let itemsPerRow = 3; // Default for larger screens

      if (width <= 600) {
        itemsPerRow = 2; // Use 2 items per row for smaller screens
      }

      let totalItems =
        Math.floor(popularItems.length / itemsPerRow) * itemsPerRow; // Remove leftover items
      if (width <= 600 && totalItems > 4) {
        totalItems = 4;
      }

      setItemsToDisplay(popularItems.slice(0, totalItems)); // Slice the array based on the calculated total
    };

    // Update the items to display based on screen size initially
    updateItemsToDisplay();

    // Add event listener for window resize
    window.addEventListener("resize", updateItemsToDisplay);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateItemsToDisplay);
    };
  }, [popularItems]);

  return (
    <section className="popular-items-container">
      <h3 className="section-title">Popular Items</h3>
      {itemsToDisplay.length === 0 ? (
        <p>No popular items at this time</p>
      ) : (
        <div className="mini-inventory-grid">
          {itemsToDisplay.map((item) => (
            <InventoryItemContainer
              key={item.id}
              item={item}
              isSelecting={false}
              isSelected={false}
              onItemClick={() => {}}
              fromPage={"/"}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export { PopularItemsSection, HeroSection, InfoContainer };
