import React from 'react';
import { HeroSection, InfoContainer, PopularItemsSection } from './FrontPageModules'; // Importing HighlightContainer and InfoContainer
import './Home.css';
import AuctionNavigation from './AuctionNavigation';

const Home = () => {
  return (
    <div className="home">
      {/* <TopItemsCarousel /> */}
      <HeroSection />
      <PopularItemsSection />
      <AuctionNavigation />

      <InfoContainer />
    </div>
  );
};

export default Home;