import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import InventoryItemContainer from "../Inventory/InventoryItemContainer";
import "../Inventory/Inventory.css"; // Reusing the Inventory styles
import constants from "../../Constants";

const MAX_ITEMS_PER_PAGE = 12; // Optional: If you want pagination

const MyBids = () => {
  const [winningItems, setWinningItems] = useState([]);
  const [losingItems, setLosingItems] = useState([]);
  const [wonItems, setWonItems] = useState([]);
  const [pageItems, setPageItems] = useState([]);
  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    const fetchWinningItems = async () => {
      try {
        const response = await axiosInstance.get(
          `/${constants.SELLER}/items-winning/`
        );
        setWinningItems(response.data);
      } catch (error) {
        console.error("Error fetching winning items:", error);
      }
    };

    const fetchLosingItems = async () => {
      try {
        const response = await axiosInstance.get(
          `/${constants.SELLER}/items-losing/`
        );
        setLosingItems(response.data);
      } catch (error) {
        console.error("Error fetching losing items:", error);
      }
    };

    const fetchWonItems = async () => {
      try {
        const response = await axiosInstance.get(
          `/${constants.SELLER}/items-won/`
        );
        setWonItems(response.data);
      } catch (error) {
        console.error("Error fetching won items:", error);
      }
    };

    fetchWinningItems();
    fetchLosingItems();
    fetchWonItems();
  }, []);

  useEffect(() => {
    const items = [...losingItems, ...winningItems, ...wonItems];
    const startIdx = selectedPage * MAX_ITEMS_PER_PAGE;
    const endIdx = Math.min(startIdx + MAX_ITEMS_PER_PAGE, items.length);
    setPageItems(items.slice(startIdx, endIdx));
    setPageCount(Math.ceil(items.length / MAX_ITEMS_PER_PAGE));
  }, [wonItems, winningItems, losingItems, selectedPage]);

  const handlePageChange = (pageIndex) => {
    setSelectedPage(pageIndex);
  };

  return (
    <div className="inventory-page">
      <div className="inventory-title-container">
        <h3>My Bids</h3> {/* Replace with chosen title */}
      </div>
      <hr className="title-divider" />
      <div className="inventory-grid">
        {pageItems.length === 0 ? (
          <p className="empty-inventory-message">You have no active bids</p>
        ) : (
          pageItems.map((item) => (
            <InventoryItemContainer
              key={item.id}
              item={item}
              fromPage={"/my-bids"}
            />
          ))
        )}
      </div>
      {pageCount > 1 && (
        <div className="pagination">
          {Array.from({ length: pageCount }, (_, index) => (
            <button
              key={index}
              className={`page-button ${
                selectedPage === index ? "active" : ""
              }`}
              onClick={() => handlePageChange(index)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyBids;
