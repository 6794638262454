import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';
import './Auth.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [shake, setShake] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return; // Prevent new requests if already loading
    setLoading(true);
    setShake(false);

    const data = { username, password };

    try {
      const response = await axiosInstance.post('login/', data);

      if (response.status === 200) {
        const { Token, UserType } = response.data;
        // Store username, token, and user type in localStorage
        localStorage.setItem('username', username);
        localStorage.setItem('token', Token);
        localStorage.setItem('userType', UserType); // Store the user type
        // Redirect to home page after successful login
        window.location.href = '/';
      } else {
        console.error('Login failed');
        setErrorMessage('Login failed. Please try again.');
        setShake(true);
      }
    } catch (error) {
      setErrorMessage('Login failed. Please try again.');
      setShake(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className={`auth-box`}>
        {loading && <div className="loading-bar"></div>}
        <h2>Login</h2>
        {errorMessage && <p className={`error-message ${shake ? 'shake' : ''}`}>{errorMessage}</p>}
        <form onSubmit={handleSubmit}>
          <div className="auth-form-group">
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              disabled={loading}
            />
          </div>
          <div className="auth-form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={loading}
            />
          </div>
          <button type="submit" className="auth-button" disabled={loading}>Login</button>
        </form>
        <div>
          <p>Don't have an account? <Link to="/register" className="auth-link">Register</Link></p>
          <p><Link to="/password-reset" className="auth-link">Forgot username/password?</Link></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
