import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Compressor from "compressorjs";
import axiosInstance from "../../axiosInstance";
import constants from "../../Constants";
import "./AddItem.css";

const MAX_IMAGE_SIZE_MB = 1;

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const AddItem = () => {
  const location = useLocation();
  const givenAuctionId = location.state?.auctionId || "None";

  const [images, setImages] = useState([]);
  const [thumbnailIndex, setThumbnailIndex] = useState(0); // New state to track the thumbnail index
  const [itemDetails, setItemDetails] = useState({
    name: "",
    condition: "New",
    description: "",
    startPrice: "",
    auction: givenAuctionId ? givenAuctionId : "None",
  });

  const [auctions, setAuctions] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState([]); // Initialize as an array
  const [selectingThumbnail, setSelectingThumbnail] = useState(false); // Track if we're selecting a thumbnail
  const [deletingImages, setDeletingImages] = useState(false); // Track if we're deleting images
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuctions = async () => {
      try {
        const [activeAuctions, futureAuctions] = await Promise.all([
          axiosInstance.get(`${constants.SELLER}/active-auctions/`),
          axiosInstance.get(`${constants.SELLER}/future-auctions/`),
        ]);

        setAuctions([...activeAuctions.data, ...futureAuctions.data]);
      } catch (error) {
        console.error("Error fetching auctions:", error);
      }
    };

    fetchAuctions();
  }, []);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setImages([...images, ...files]);
  };

  const handleImageDelete = (indexToDelete) => {
    const updatedImages = images.filter((_, index) => index !== indexToDelete);
    setImages(updatedImages);
    if (thumbnailIndex === indexToDelete && updatedImages.length > 0) {
      setThumbnailIndex(0);
    }
    if (updatedImages.length === 0) {
      setDeletingImages(false); // Exit delete mode if all images are deleted
    }
  };

  const handleThumbnailSelect = (index) => {
    setThumbnailIndex(index);
    setSelectingThumbnail(false); // Exit thumbnail selection mode
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemDetails({ ...itemDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);

    if (images.length === 0) {
      setUploadStatus("Please upload at least one image.");
      setLoading(false);
      return;
    }

    if (parseFloat(itemDetails.startPrice) <= 0) {
      setUploadStatus("Please enter a positive start price.");
      setLoading(false);
      return;
    }

    try {
      const payload = { ...itemDetails };
      if (payload.auction === "None") delete payload.auction;

      const response = await axiosInstance.post(
        "auctioneer/post-item/",
        payload
      );
      const newItem = response.data;
      const itemId = newItem.id;

      const convertPngToJpeg = (image) => {
        return new Promise((resolve) => {
          const canvas = document.createElement("canvas");
          const img = new Image();
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            canvas.toBlob(
              (blob) => {
                // Create a new File object with the correct extension
                const jpegFile = new File(
                  [blob],
                  image.name.replace(/\.png$/, ".jpg"),
                  { type: "image/jpeg" }
                );
                resolve(jpegFile);
              },
              "image/jpeg",
              0.9
            );
          };
          img.src = URL.createObjectURL(image);
        });
      };

      const uploadImage = async (image, index, isThumbnail = false) => {
        console.log("original image size: ", image.size);
        const uploadImageEndpoint = isThumbnail
          ? "auctioneer/post-item-thumbnail/"
          : "auctioneer/post-item-image/";
        const isPng = image.type === "image/png";
        const compressImage = (image, quality = 0.5) => {
          return new Promise((resolve, reject) => {
            new Compressor(image, {
              quality: quality,
              maxWidth: 1920,
              maxHeight: 1920,
              success: resolve,
              error: reject,
            });
          });
        };

        if (isPng) {
          image = await convertPngToJpeg(image);
        }

        let compressedImage = await compressImage(image);
        let maxCompressCount = 10;
        let compressions = 0;
        while (
          (compressedImage.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) &
          (compressions < maxCompressCount)
        ) {
          compressedImage = await compressImage(compressedImage, 0.05); // Reduce quality further if necessary
          compressions++;
        }

        console.log("Compressed size: ", compressedImage.size);
        setUploadStatus("Image compressed to:", compressedImage.size);

        const formData = new FormData();
        formData.append("image", compressedImage, image.name);
        formData.append("item", itemId);

        await axiosInstance.post(uploadImageEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress((prev) => {
              const newProgress = [...prev];
              newProgress[index] = percentCompleted;
              return newProgress;
            });
          },
        });
      };

      // Upload the thumbnail first
      await uploadImage(images[thumbnailIndex], 0, true);

      // Upload the rest of the images
      const otherImages = images.filter((_, index) => index !== thumbnailIndex);
      const uploadPromises = otherImages.map((image, index) =>
        uploadImage(image, index + 1)
      );

      await Promise.all(uploadPromises);

      setUploadStatus("Upload Successful!");
      await sleep(1000);
      if (givenAuctionId !== "None") {
        navigate("/auction-contents/" + givenAuctionId);
      } else {
        navigate("/inventory");
      }
    } catch (error) {
      console.error(
        "Error adding item and uploading images:",
        error.response.data
      );
      setUploadStatus(
        "Error uploading item and images. Please try again." +
          JSON.stringify(error.response.data)
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-item-page">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className="inventory-title-container">
        <h3>Add Item</h3>
      </div>
      <hr className="title-divider"></hr>
      <div className="image-upload-section">
        <div className="title-container">
          <h2>Images</h2>
        </div>
        {images.length > 0 && (
          <div className="image-actions">
            {!selectingThumbnail && !deletingImages && (
              <>
                <button
                  className="add-item-button"
                  onClick={() => {
                    setSelectingThumbnail(true);
                    setDeletingImages(false);
                  }}
                  disabled={deletingImages}
                >
                  Select Thumbnail
                </button>
                <button
                  className="add-item-button"
                  onClick={() => {
                    setDeletingImages(true);
                    setSelectingThumbnail(false);
                  }}
                  disabled={selectingThumbnail}
                >
                  Delete Images
                </button>
              </>
            )}
            {deletingImages && (
              <button
                className="add-item-button"
                onClick={() => {
                  setDeletingImages(false);
                }}
              >
                Done
              </button>
            )}
          </div>
        )}
        <div className="images-preview">
          {images.map((image, index) => (
            <div
              key={index}
              className={`image-container ${
                thumbnailIndex === index ? "thumbnail" : ""
              } ${selectingThumbnail || deletingImages ? "acting-upon" : ""}`}
              onClick={() => {
                if (selectingThumbnail) handleThumbnailSelect(index);
                if (deletingImages) handleImageDelete(index);
              }}
            >
              <img
                src={URL.createObjectURL(image)}
                alt={`Preview ${index}`}
                className={`image-preview ${
                  selectingThumbnail || deletingImages ? "acting-upon" : ""
                }`}
              />
              {selectingThumbnail && (
                <div className="select-indicator">Select</div>
              )}
              {deletingImages && <div className="delete-indicator">Delete</div>}
            </div>
          ))}
        </div>
        {!selectingThumbnail && !deletingImages && (
          <div className="upload-button-container">
            <button className="upload-button">
              <input
                type="file"
                multiple
                onChange={handleImageUpload}
                className="file-input"
              />
              +
            </button>
          </div>
        )}
      </div>
      <div className="details-section">
        <h2>Details</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Item Name:
            <input
              type="text"
              name="name"
              value={itemDetails.name}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Condition:
            <select
              name="condition"
              value={itemDetails.condition}
              onChange={handleInputChange}
              required
            >
              <option value="New">New</option>
              <option value="Like New">Like New</option>
              <option value="Good Condition">Good Condition</option>
              <option value="Used">Used</option>
            </select>
          </label>
          <label>
            Description:
            <textarea
              name="description"
              value={itemDetails.description}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Start Price:
            <input
              type="number"
              name="startPrice"
              value={itemDetails.startPrice}
              onChange={handleInputChange}
              min="0.01"
              step="0.01"
              required
            />
          </label>
          {givenAuctionId === "None" && (
            <label>
              Auction:
              <select
                name="auction"
                value={itemDetails.auction}
                onChange={handleInputChange}
              >
                <option value="None">None</option>
                {auctions.map((auction) => (
                  <option key={auction.id} value={auction.id}>
                    {auction.name}
                  </option>
                ))}
              </select>
            </label>
          )}
          <button className="add-item-button">Submit</button>
        </form>
        {uploadStatus && <p>{uploadStatus}</p>}
      </div>
      {progress.length > 0 && (
        <div className="images-progress-section">
          {progress.map((percent, index) => (
            <div key={index} className="images-progress-bar">
              <div className="images-progress-label">Image {index}</div>
              <div className="images-progress" style={{ width: `${percent}%` }}>
                {percent}%
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddItem;
