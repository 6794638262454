import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© Copyright 2024 | JMJ Liquidation</p>
        <p>2013 Wells Branch Pkwy Suite 307, Austin, TX 78728</p>
        <p>(512) 996-5120</p>
      </div>
    </footer>
  );
};

export default Footer;