import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import axiosInstance from "../../axiosInstance";
import constants from "../../Constants";
import "./AddItem.css";

const MAX_IMAGE_SIZE_MB = 0.25;

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const EditItem = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();

  const [images, setImages] = useState([]);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const [itemDetails, setItemDetails] = useState({
    name: "",
    condition: "",
    description: "",
    startPrice: "",
    auction: "",
  });

  const [auctions, setAuctions] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState([]);
  const [selectingThumbnail, setSelectingThumbnail] = useState(false);
  const [deletingImages, setDeletingImages] = useState(false);

  useEffect(() => {
    const fetchAuctions = async () => {
      try {
        const [activeAuctions, futureAuctions] = await Promise.all([
          axiosInstance.get(`${constants.SELLER}/active-auctions/`),
          axiosInstance.get(`${constants.SELLER}/future-auctions/`),
        ]);

        setAuctions([...activeAuctions.data, ...futureAuctions.data]);
      } catch (error) {
        console.error("Error fetching auctions:", error);
      }
    };

    const fetchItemDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `/${constants.SELLER}/item/${itemId}/`
        );
        const itemData = response.data;
        console.log(itemData.auction)
        setItemDetails({
          name: itemData.name,
          condition: itemData.condition,
          description: itemData.description,
          startPrice: itemData.startPrice,
          auction: itemData.auctionId ? itemData.auctionId : "None",
        });
      } catch (error) {
        console.error("Error fetching item details:", error);
      }
    };

    const fetchItemImages = async () => {
      try {
        const response = await axiosInstance.get(
          `/${constants.SELLER}/item/${itemId}/item-images/`
        );
        setImages(response.data);
      } catch (error) {
        console.error("Error fetching item images:", error);
      }
    };

    fetchAuctions();
    fetchItemDetails();
    fetchItemImages();
  }, [itemId]);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setImages([...images, ...files]);
  };

  const handleImageDelete = (indexToDelete) => {
    const updatedImages = images.filter((_, index) => index !== indexToDelete);
    setImages(updatedImages);
    if (thumbnailIndex === indexToDelete && updatedImages.length > 0) {
      setThumbnailIndex(0);
    }
    if (updatedImages.length === 0) {
      setDeletingImages(false);
    }
  };

  const handleThumbnailSelect = (index) => {
    setThumbnailIndex(index);
    setSelectingThumbnail(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemDetails({ ...itemDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);

    if (images.length === 0) {
      setUploadStatus("Please upload at least one image.");
      setLoading(false);
      return;
    }

    if (parseFloat(itemDetails.startPrice) <= 0) {
      setUploadStatus("Please enter a positive start price.");
      setLoading(false);
      return;
    }

    try {
      const payload = { ...itemDetails };
      if (payload.auction === "None") delete payload.auction;
      payload.id = itemId
      console.log(payload)

      await axiosInstance.post(`auctioneer/edit-item/${itemId}/`, payload);
      setUploadStatus("Update Successful!");
      await sleep(1000);
      navigate("/inventory");
    } catch (error) {
      console.error("Error updating item:", error.response.data);
      setUploadStatus(
        "Error updating item. Please try again." +
        JSON.stringify(error.response.data)
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-item-page">
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      <div className="inventory-title-container">
        <h3>Edit Item</h3>
      </div>
      <hr className="title-divider"></hr>
      <div className="image-upload-section">
        <div className="title-container">
          <h2>Images</h2>
        </div>
        {images.length > 0 && (
          <div className="image-actions">
            {!selectingThumbnail && !deletingImages && (
              <>
                <button
                  className="add-item-button"
                  onClick={() => {
                    setSelectingThumbnail(true);
                    setDeletingImages(false);
                  }}
                  disabled={deletingImages}
                >
                  Select Thumbnail
                </button>
                <button
                  className="add-item-button"
                  onClick={() => {
                    setDeletingImages(true);
                    setSelectingThumbnail(false);
                  }}
                  disabled={selectingThumbnail}
                >
                  Delete Images
                </button>
              </>
            )}
            {deletingImages && (
              <button
                className="add-item-button"
                onClick={() => {
                  setDeletingImages(false);
                }}
              >
                Done
              </button>
            )}
          </div>
        )}
        <div className="images-preview">
          {images.map((image, index) => (
            <div
              key={index}
              className={`image-container ${thumbnailIndex === index ? "thumbnail" : ""
                } ${selectingThumbnail || deletingImages ? "acting-upon" : ""}`}
              onClick={() => {
                if (selectingThumbnail) handleThumbnailSelect(index);
                if (deletingImages) handleImageDelete(index);
              }}
            >
              <img
                // Check if image is an object and has a property 'url', if not use URL.createObjectURL
                src={
                  typeof image === "object"
                    ? `${constants.BACKEND_ADDRESS}${image.image}`
                    : image instanceof File
                      ? URL.createObjectURL(image)
                      : image
                }
                alt={`Preview ${index}`}
                className={`image-preview ${selectingThumbnail || deletingImages ? "acting-upon" : ""
                  }`}
              />
              {selectingThumbnail && (
                <div className="select-indicator">Select</div>
              )}
              {deletingImages && <div className="delete-indicator">Delete</div>}
            </div>
          ))}
        </div>

        {!selectingThumbnail && !deletingImages && (
          <div className="upload-button-container">
            <button className="upload-button">
              <input
                type="file"
                multiple
                onChange={handleImageUpload}
                className="file-input"
              />
              +
            </button>
          </div>
        )}
      </div>
      <div className="details-section">
        <h2>Details</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Item Name:
            <input
              type="text"
              name="name"
              value={itemDetails.name}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Condition:
            <select
              name="condition"
              value={itemDetails.condition}
              onChange={handleInputChange}
              required
            >
              <option value="New">New</option>
              <option value="Like New">Like New</option>
              <option value="Good Condition">Good Condition</option>
              <option value="Used">Used</option>
            </select>
          </label>
          <label>
            Description:
            <textarea
              name="description"
              value={itemDetails.description}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Start Price:
            <input
              type="number"
              name="startPrice"
              value={itemDetails.startPrice}
              onChange={handleInputChange}
              min="0.01"
              step="0.01"
              required
            />
          </label>
          <label>
            Auction:
            <select
              name="auction"
              value={itemDetails.auction}
              onChange={handleInputChange}
            >
              <option value="None">None</option>
              {auctions.map((auction) => (
                <option key={auction.id} value={auction.id}>
                  {auction.name}
                </option>
              ))}
            </select>
          </label>
          <button className="add-item-button">Submit</button>
        </form>
        {uploadStatus && <p>{uploadStatus}</p>}
      </div>
      {progress.length > 0 && (
        <div className="images-progress-section">
          {progress.map((percent, index) => (
            <div key={index} className="images-progress-bar">
              <div className="images-progress-label">Image {index}</div>
              <div className="images-progress" style={{ width: `${percent}%` }}>
                {percent}%
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EditItem;
