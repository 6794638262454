import React, { useEffect, useRef, useState } from 'react';
import './BuyerAccountPage.css';
import constants from '../../Constants';
import axiosInstance from '../../axiosInstance';

const BuyerAccountPage = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [isSeller, setIsSeller] = useState(false);
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState(null);
    const [userDetails, setUserDetails] = useState(null);


    const fetchAccountDetails = async () => {
        try {
            const response = await axiosInstance.get(`/account-info/`);
            console.log(response)
            setUserDetails(response.data)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching account details:', error);
        }
    };

    useEffect(() => {
        // Check if user is logged in
        const token = localStorage.getItem('token');
        if (token) {
            setLoggedIn(true);

            // Check if user is the specific seller
            setUsername(localStorage.getItem('username'));
            const userType = localStorage.getItem('userType');
            if (userType === 'Seller' && username === constants.SELLER) {
                setIsSeller(true);
            }
        }

        fetchAccountDetails();

    }, []);

    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm("Are you sure you'd like to delete your account? This action cannot be undone.");
        if (!confirmDelete) {
            return
        }

        const confirmationInput = window.prompt("Type 'delete' to confirm account deletion.");
        if (confirmationInput !== 'delete') {
            alert("Account deletion cancelled or incorrect input.");
            return
        }
        try {
            const response = await axiosInstance.delete(`/delete-account/`);
            console.log(response);
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('userType');
            // Redirect to login page
            window.location.href = '/';
        } catch (error) {
            console.error('Error deleting account:', error);

            const errorMessage = error.response.data.error;
            const existingBids = error.response.data.existingBids;
            if (existingBids) {
                const existingBidsNames = existingBids.map(item => item.name).join('\n');
                alert(errorMessage + '\n\nItems: \n\n' + existingBidsNames);
            } else {
                alert(errorMessage)
            }
        };
    }


    return (
        <div className="view-account-page">
            <div className="inventory-title-container">
                <h3>Account Details</h3>
            </div>
            <hr className='title-divider'></hr>
            {!loading &&
                <div className="item-details">
                    <div className="details-section">
                        <div>
                            <strong>Username:</strong> <span>{userDetails.username}</span>
                        </div>
                        <div>
                            <strong>Email: </strong> <span>{userDetails.email}</span>
                        </div>
                        {isSeller &&
                            <div>
                                <strong>Account Type: </strong> <span>{userDetails.accountType}</span>
                            </div>
                        }
                    </div>
                    <button className='delete-button' onClick={handleDeleteAccount}>Delete Account</button>
                </div>
            }

        </div>
    )
}


export default BuyerAccountPage;