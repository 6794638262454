import React, { useEffect, useState } from "react";
import "./CountdownTimer.css";

const CountdownTimer = ({ startTime, endTime, onEnd }) => {
  const [remainingTime, setRemainingTime] = useState({});

  const calculateRemainingTime = (startTime, endTime) => {
    const startDate = new Date(startTime).getTime();
    const endDate = new Date(endTime).getTime();
    const now = new Date().getTime();

    const distanceToStart = startDate - now;
    const distanceToEnd = endDate - now;

    if (distanceToStart > 0) {
      const days = Math.floor(distanceToStart / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distanceToStart % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (distanceToStart % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((distanceToStart % (1000 * 60)) / 1000);

      return { type: "start", days, hours, minutes, seconds };
    } else {
      const days = Math.floor(distanceToEnd / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distanceToEnd % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (distanceToEnd % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((distanceToEnd % (1000 * 60)) / 1000);

      return { type: "end", days, hours, minutes, seconds };
    }
  };

  useEffect(() => {
    const updateRemainingTime = () => {
      setRemainingTime(calculateRemainingTime(startTime, endTime));
    };

    // Initial call to set the time
    updateRemainingTime();

    // Update remaining time every second
    const intervalId = setInterval(updateRemainingTime, 1000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [startTime, endTime]);

  useEffect(() => {
    if (
      remainingTime.type === "end" &&
      remainingTime.days === 0 &&
      remainingTime.hours === 0 &&
      remainingTime.minutes === 0 &&
      remainingTime.seconds === 0
    ) {
      if (onEnd) {
        onEnd();
      }
    }
  }, [remainingTime, onEnd]);

  return (
    <div
      className={`countdown-timer ${
        remainingTime.type === "start" ? "start" : ""
      }`}
    >
      <p>
        {remainingTime.type === "start" ? "Starts in: " : "Closes in: "}
        {remainingTime.days > 0 && `${remainingTime.days}d `}
        {remainingTime.days > 0 || remainingTime.hours > 0
          ? `${remainingTime.hours}h `
          : ""}
        {remainingTime.days > 0 ||
        remainingTime.hours > 0 ||
        remainingTime.minutes > 0
          ? `${remainingTime.minutes}m `
          : ""}
        {`${remainingTime.seconds}s`}
      </p>
    </div>
  );
};

export default CountdownTimer;
