import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import axiosInstance from "../../axiosInstance";
import constants from "../../Constants";
import JMJLogo from "../../Assets/jmjlogo.png";

const Navbar = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const profileDropdownRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoggedIn(true);

      const username = localStorage.getItem("username");
      const userType = localStorage.getItem("userType");
      if (userType === "Seller" && username === constants.SELLER) {
        setIsSeller(true);
      }
    }

    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target)
      ) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick, true);

    return () => {
      document.removeEventListener("click", handleDocumentClick, true);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await axiosInstance.post(
        "logout/",
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userType");
      window.location.href = "/login";
    } catch (error) {
      console.error("Error logging out:", error);
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userType");
      window.location.href = "/login";
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  return (
    <nav className="navbar" ref={dropdownRef}>
      <div className="navbar-logo-title">
        <Link to="/">
          <img src={JMJLogo} alt="JMJ Logo" className="navbar-logo" />
        </Link>
        <h2 className="navbar-title">
          <Link to="/" className="nav-link">
            JMJ Auctions
          </Link>
        </h2>
      </div>

      <div className="dropdown-icon" onClick={toggleDropdown}>
        <span>&#9776;</span>
      </div>
      <ul className={`nav-links ${isDropdownOpen ? "open" : ""}`}>
        <li>
          <Link to="/auctions" className="nav-link">
            All Auctions
          </Link>
        </li>
        {loggedIn && !isSeller && (
          <li>
            <Link to="/my-bids" className="nav-link">
              My Bids
            </Link>
          </li>
        )}
        {!isSeller && (
          <li>
            <Link to="/pick-up-details" className="nav-link">
              Pickup Details
            </Link>
          </li>
        )}
        {loggedIn && isSeller && (
          <li>
            <Link to="/inventory" className="nav-link">
              Inventory
            </Link>
          </li>
        )}
        {loggedIn && isSeller && (
          <li>
            <Link to="/sales" className="nav-link">
              Sales
            </Link>
          </li>
        )}
        {loggedIn ? (
          <li className="profile-dropdown-container" ref={profileDropdownRef}>
            <span className="profile-name" onClick={toggleProfileDropdown}>
              {localStorage.getItem("username")}{" "}
              <span className="arrow">&#9662;</span>
            </span>
            {isProfileDropdownOpen && (
              <div className="profile-dropdown">
                <span className="nav-link logout" onClick={handleLogout}>
                  Logout
                </span>
                <span className="nav-link logout">
                  <Link to="/account">Account</Link>
                </span>
              </div>
            )}
          </li>
        ) : (
          <li>
            <Link to="/login" className="nav-link">
              Login
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
