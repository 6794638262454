import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import constants from "../../Constants";
import "./AuctionNavigation.css"; // Import the CSS file for styling

const AuctionNavigation = () => {
  const [weeklyAuction, setWeeklyAuction] = useState(null);

  useEffect(() => {
    const fetchAuctions = async () => {
      try {
        const [activeResponse, futureResponse] = await Promise.all([
          axiosInstance.get(`${constants.SELLER}/active-auctions/`),
          axiosInstance.get(`${constants.SELLER}/future-auctions/`),
        ]);

        // Combine the data from both responses
        const combinedAuctions = [
          ...activeResponse.data,
          ...futureResponse.data,
        ];

        const weeklyAuction = combinedAuctions.find((auction) =>
          auction.name.startsWith("Weekly Auction")
        );
        setWeeklyAuction(weeklyAuction);
      } catch (error) {
        console.error("Error fetching auctions:", error);
      }
    };

    fetchAuctions();
  }, []);

  const calculateTimeLeft = (startTime, endTime) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const now = new Date();

    if (start > now) {
      const diffTime = start - now;

      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor(
        (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMinutes = Math.floor(
        (diffTime % (1000 * 60 * 60)) / (1000 * 60)
      );

      if (diffDays > 0) {
        return `Starts in ${diffDays} day${diffDays > 1 ? "s" : ""}`;
      } else if (diffHours > 0) {
        return `Starts in ${diffHours} hour${diffHours > 1 ? "s" : ""}`;
      } else {
        return `Starts in ${diffMinutes} minute${diffMinutes > 1 ? "s" : ""}`;
      }
    } else {
      const diffTime = Math.max(end - now, 0); // Ensure no negative values

      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor(
        (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const diffMinutes = Math.floor(
        (diffTime % (1000 * 60 * 60)) / (1000 * 60)
      );

      if (diffDays > 0) {
        return `Ends in ${diffDays} day${diffDays > 1 ? "s" : ""}`;
      } else if (diffHours > 0) {
        return `Ends in ${diffHours} hour${diffHours > 1 ? "s" : ""}`;
      } else {
        return `Ends in ${diffMinutes} minute${diffMinutes > 1 ? "s" : ""}`;
      }
    }
  };

  return (
    <div className="auction-navigation-container">
      <div className="auction-navigation-container-content">
        <h2>Auctions</h2>
        <p>
          We separate our items into different Auctions. Each auction has a start date and an end date.
          Every item within an auction can be bid on separately until the auction ends. When an auction ends,
          each item in the auction will be sold to its highest bidder. Don't miss out!
        </p>
        <div className="auction-navigation-links">
          <Link
            to={weeklyAuction ? `/auction-contents/${weeklyAuction.id}` : "#"}
            className="auction-navigation-item"
          >
            {weeklyAuction && (
              <div>
                <h5>{weeklyAuction.name}</h5>
                <p>
                  {calculateTimeLeft(
                    weeklyAuction.startTime,
                    weeklyAuction.endTime
                  )}
                </p>
              </div>
            )}
          </Link>
          <Link to="/auctions" className="all-auction-navigation-item">
            View All Auctions
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuctionNavigation;
